import AutoLinkText from '@/components/AutoLinkText'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Tabs, { TabThemeType } from '@/components/Tabs'
import TransitionHandler from '@/components/TransitionHandler'
import elementItemHelper from '@/helpers/elementItemHelper'
import { STYLE_ARROW_BUTTON_WHITE } from '@/styles/buttons'
import {
  FONT_16,
  FONT_16_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import Grid from '../Grid'

interface Grid2FullReportTabProps {
  item: ContentRow
}

const Partial = {
  Title({ title }: { title: ContentTextItem[] }) {
    return (
      <>
        {title.map(({ show, value, as }, index) => {
          return (
            <TransitionHandler key={`Grid2FullReportTab-title-${index}`}>
              <DisplayConfigWrapper show={show}>
                <Styled.Title as={as}>{value}</Styled.Title>
              </DisplayConfigWrapper>
            </TransitionHandler>
          )
        })}
      </>
    )
  },
  Image({ image }: { image: ContentImageItem[] }) {
    return (
      <>
        {image.map(({ show, src, alt }, index) => {
          return (
            <TransitionHandler key={`Grid2FullReportTab-image-${index}`}>
              <DisplayConfigWrapper show={show}>
                <Styled.Image src={src} alt={alt || ''} />
              </DisplayConfigWrapper>
            </TransitionHandler>
          )
        })}
      </>
    )
  },
  TabTitle({ tabTitle }: { tabTitle: ContentTextItem }) {
    return (
      <TransitionHandler>
        <Styled.TabTitle>{tabTitle.value}</Styled.TabTitle>
      </TransitionHandler>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    return (
      <>
        {text.map(({ show, value }, index) => {
          return (
            <TransitionHandler key={`Grid2FullReportTab-text-${index}`}>
              <DisplayConfigWrapper show={show}>
                <Styled.Text>{value}</Styled.Text>
              </DisplayConfigWrapper>
            </TransitionHandler>
          )
        })}
      </>
    )
  },
  TabButton({ tabButton }: { tabButton: ContentButtonItem }) {
    return (
      <TransitionHandler>
        <Styled.TabButton href={tabButton.href}>
          {tabButton.label}
        </Styled.TabButton>
      </TransitionHandler>
    )
  },
}

function Grid2FullReportTab({ item }: Grid2FullReportTabProps) {
  const { columns, type, htmlId, isScrollToHash } = item
  const [{ items: leftColumnItems }, { items: rightColumnItems }] = columns ?? [
    {},
    {},
  ]

  const { title, image } = elementItemHelper(leftColumnItems)
  const { tab, text, tabTitle, tabButton } = elementItemHelper(rightColumnItems)
  const latestTabIndex = tabTitle?.length - 1

  const [tabId, setTabId] = useState<number>(latestTabIndex ?? 0)

  const handleClickTab = (index: number): void => {
    return setTabId(index)
  }

  return (
    <Styled.Wrap id={htmlId} data-is-scroll-to-hash={isScrollToHash}>
      <Styled.grid gridType={type}>
        <div>
          <Partial.Title title={title} />
          <Partial.Image image={image} />
        </div>
        <Styled.RightColumn>
          <Styled.Tabs
            list={tab}
            onClick={handleClickTab}
            tabTheme={TabThemeType.BLACK04_YELLOW}
            activeTabId={latestTabIndex}
            hiddenTitle={item?.tabHiddenTitle}
            activeTabScroll
          />
          <Partial.TabTitle tabTitle={tabTitle?.[tabId]} />
          <Partial.Text text={text} />
          <Partial.TabButton tabButton={tabButton[tabId]} />
        </Styled.RightColumn>
      </Styled.grid>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    background-color: ${STYLE_COLOR.DARK_BG};
  `,
  grid: styled(Grid)`
    position: relative;
  `,
  Title: styled.div`
    white-space: pre-wrap;
    padding-top: 77px;
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 60px;
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Image: styled.img`
    width: 100%;
    padding: 80px 70px;
    box-sizing: border-box;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 60px 43px;
    }
  `,
  RightColumn: styled.div``,
  Tabs: styled(Tabs)`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      position: absolute;
      top: 77px;
      left: 50%;
      right: 0;
      border-top: 1px solid ${STYLE_COLOR.BLACK04};
      padding-top: 40px;
      margin-right: 5%;
      color: ${STYLE_COLOR.BLACK04};
    }
  `,
  TabTitle: styled.div`
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
      padding-top: 60px;
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  TabButton: styled(AutoLinkText)`
    ${FONT_16_BOLD};
    ${STYLE_ARROW_BUTTON_WHITE};
    margin-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
      padding-bottom: 64px;
    }
  `,
}

export default Grid2FullReportTab

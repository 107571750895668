import React, { PropsWithChildren, useEffect, useState } from 'react'
import Grid from '@/components/Grid'
import { ContentRow } from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Video from '@/components/Video'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  FONT_100_BOLD,
  FONT_18_EXTRA_BOLD,
  FONT_22,
  FONT_48_BOLD,
} from '@/styles/fonts'
import {
  MOBILE_HEADER_DEFAULT_HEIGHT,
  PC_HEADER_MAIN_HEIGHT,
} from '@/constants/Layout'
import {
  STYLE_PC_MASK_HOLDER_DEFAULT_TRANSITION,
  STYLE_PC_SHOW_MASK_TRANSITION,
  STYLE_PC_SHOWTITLE_TRANSITION,
} from '@/styles/Transitions'
import useAppContext from '@/contexts'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid1FullscreenIntroProps {
  item: ContentRow
}

interface TransitionElementProps {
  step: number
}

const StepTimer = [0, 500, 1400, 900, 10]

function Grid1FullscreenIntro({
  item,
}: PropsWithChildren<Grid1FullscreenIntroProps>) {
  const {
    config: { hasEnteredFullscreenIntro },
    configAction: { setHasEnteredFullScreenIntro },
  } = useAppContext()

  const { columns, type } = item
  const [{ items }] = columns ?? [{}]
  const { video, title, subTitle, image } = elementItemHelper(items)
  const key = 'Grid1FullScreenVideo'
  const [timer, setTimer] = useState(null)
  const [step, setStep] = useState(hasEnteredFullscreenIntro ? 1 : 0)

  useEffect(() => {
    const END_STEP = StepTimer.length
    if (step < END_STEP && timer === null) {
      setTimer(
        setTimeout(() => {
          setStep((prevState) => prevState + 1)
          setTimer(null)
        }, StepTimer[step])
      )
      return undefined
    }
    if (step < END_STEP) {
      return undefined
    }
  }, [step, timer])

  // 백그라운드 애니메이션이 종료되면 hasEnteredFullscreenIntro 설정
  useEffect(() => {
    if (step > 2 && !hasEnteredFullscreenIntro) {
      setHasEnteredFullScreenIntro(true)
    }
  }, [hasEnteredFullscreenIntro, setHasEnteredFullScreenIntro, step])

  const elementProps = {
    step,
  }

  return (
    <Grid gridType={type}>
      <Styled.Wrap>
        {/* 해당 컴포넌트 최초 진입시에만 백그라운드 애니메이션 노출 */}
        {!hasEnteredFullscreenIntro && <Styled.Background {...elementProps} />}
        <Styled.MaskHolder {...elementProps}>
          <Styled.MaskHolderLeft {...elementProps} />
          <Styled.MaskHolderRight {...elementProps} />
          <Styled.MaskHolderTop {...elementProps} />
          <Styled.MaskHolderBottom {...elementProps} />
        </Styled.MaskHolder>
        <Styled.MediaWrap>
          {video?.map((item, index) => (
            <DisplayConfigWrapper
              show={item.show}
              key={`${key}-video-${index}`}
            >
              <Styled.Video {...item} />
            </DisplayConfigWrapper>
          ))}
        </Styled.MediaWrap>
        <Styled.TitleHolder>
          {subTitle?.map(({ value, show }, index) => (
            <DisplayConfigWrapper show={show} key={`${key}-subTitle-${index}`}>
              <Styled.SubTitle {...elementProps}>{value}</Styled.SubTitle>
            </DisplayConfigWrapper>
          ))}
          {title?.map(({ value, show, as }, index) => (
            <DisplayConfigWrapper show={show} key={`${key}-title-${index}`}>
              <Styled.Title as={as} {...elementProps}>
                {value}
              </Styled.Title>
            </DisplayConfigWrapper>
          ))}
          {image?.map(({ src, alt, show, width, height }, index) => (
            <DisplayConfigWrapper show={show} key={`${key}-image-${index}`}>
              <Styled.Logo
                src={src}
                alt={alt || ''}
                width={width}
                height={height}
                {...elementProps}
              />
            </DisplayConfigWrapper>
          ))}
        </Styled.TitleHolder>
      </Styled.Wrap>
    </Grid>
  )
}

const Styled = {
  Background: styled.div<TransitionElementProps>`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      z-index: 9000;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: ${STYLE_COLOR.DARK_BG};
      font-size: 0;
      line-height: 0;
      white-space: nowrap;
      text-align: center;
      opacity: 1;
      transition: all 0.5s ease-out;
      ${({ step }) => {
        switch (step) {
          case 0:
            return css`
              transition: opacity 1s ease-out;
              opacity: 1;
            `
          case 2:
            return css`
              transition: opacity 1s ease-out;
              opacity: 0;
            `
          case 3:
          case 4:
          case 5:
            return css`
              display: none;
            `
        }
      }};
    }
  `,
  MaskHolder: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  `,
  MaskHolderTop: styled.div<TransitionElementProps>`
    position: absolute;
    background: ${STYLE_COLOR.WHITE01};
    top: 0;
    left: 0;
    width: 100%;
    height: 51%;
    transform: translate3d(0, -100%, 0);
    ${STYLE_PC_MASK_HOLDER_DEFAULT_TRANSITION};
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${({ step }) => {
        switch (step) {
          case 1:
            return css`
              ${STYLE_PC_SHOW_MASK_TRANSITION};
            `
          default:
            return css`
              transition: all 1s ease-out;
              top: 0;
            `
        }
      }};
    }
  `,
  MaskHolderRight: styled.div<TransitionElementProps>`
    position: absolute;
    background: ${STYLE_COLOR.WHITE01};
    top: -${PC_HEADER_MAIN_HEIGHT}px;
    left: -50%;
    width: 50%;
    height: 100%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: -${MOBILE_HEADER_DEFAULT_HEIGHT}px;
    }
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${STYLE_PC_MASK_HOLDER_DEFAULT_TRANSITION};
      ${({ step }) => {
        switch (step) {
          case 1:
            return css`
              ${STYLE_PC_SHOW_MASK_TRANSITION};
            `
          default:
            return css`
              transition: all 1.2s ease-out;
              transition-delay: 0.4s;
              left: -282px;
            `
        }
      }};
    }
  `,
  MaskHolderBottom: styled.div<TransitionElementProps>`
    position: absolute;
    background: ${STYLE_COLOR.WHITE01};
    top: 100vh;
    left: 0;
    width: 100%;
    height: 51%;
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${STYLE_PC_MASK_HOLDER_DEFAULT_TRANSITION};
      ${({ step }) => {
        switch (step) {
          case 1:
            return css`
              ${STYLE_PC_SHOW_MASK_TRANSITION};
            `
          default:
            return css`
              transition: all 1s ease-out;
              top: 100vh;
            `
        }
      }};
    }
  `,
  MaskHolderLeft: styled.div<TransitionElementProps>`
    position: absolute;
    background: ${STYLE_COLOR.WHITE01};
    top: -${PC_HEADER_MAIN_HEIGHT}px;
    right: -50%;
    width: 50%;
    height: 100%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: -${MOBILE_HEADER_DEFAULT_HEIGHT}px;
    }
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${STYLE_PC_MASK_HOLDER_DEFAULT_TRANSITION};
      ${({ step }) => {
        switch (step) {
          case 1:
            return css`
              ${STYLE_PC_SHOW_MASK_TRANSITION};
            `
          default:
            return css`
              transition: all 1.2s ease-out;
              transition-delay: 0.4s;
              right: -282px;
            `
        }
      }};
    }
  `,
  Wrap: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      overflow: hidden;
    }
  `,
  MediaWrap: styled.div`
    overflow: hidden;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
      height: 100vh;
      margin-top: -${MOBILE_HEADER_DEFAULT_HEIGHT}px;
    }
  `,
  Video: styled(Video)`
    height: 100vh;
    min-height: 100%;
    margin-top: -${PC_HEADER_MAIN_HEIGHT}px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      margin-top: 0;
    }
  `,
  TitleHolder: styled.div`
    position: absolute;
    text-align: center;
    margin-top: -87px;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    // width: 100%; - ie 가로스크롤 생성되는 문제로 remove.
  `,
  Title: styled.div<TransitionElementProps>`
    transform: translate3d(0, 20px, 0);
    padding-top: 12px;
    ${FONT_100_BOLD};
    color: ${STYLE_COLOR.YELLOW};
    opacity: 0;
    white-space: pre-wrap;

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${({ step }) => {
        switch (step) {
          case 3:
          case 4:
          case 5:
            return css`
              ${STYLE_PC_SHOWTITLE_TRANSITION};
            `
        }
      }};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_48_BOLD};
      ${({ step }) => {
        switch (step) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return css`
              transition: opacity 0.8s 0.5s ease-out;
              opacity: 1;
            `
        }
      }};
    }
  `,
  SubTitle: styled.div<TransitionElementProps>`
    ${FONT_22};
    color: ${STYLE_COLOR.WHITE01};
    transform: translate3d(0, 20px, 0);
    opacity: 0;

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${({ step }) => {
        switch (step) {
          case 3:
          case 4:
          case 5:
            return css`
              ${STYLE_PC_SHOWTITLE_TRANSITION};
            `
        }
      }};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
      ${({ step }) => {
        switch (step) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return css`
              transition: opacity 1s 1s ease-out;
              opacity: 1;
            `
        }
      }};
    }
  `,
  Logo: styled.img<TransitionElementProps>`
    top: 50%;
    margin-top: 0;
    opacity: 0;
    transform: scale(0.5);

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${({ step }) => {
        switch (step) {
          case 3:
          case 4:
          case 5:
            return css`
              transition: transform 0.6s ease-out, opaicty 0.6s ease-out,
                margin-top 0.5s 0.6s ease-out;
              margin-top: 30px;
              opacity: 1;
              transform: scale(1);
            `
        }
      }};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${({ step }) => {
        switch (step) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return css`
              transition: all 0.8s 0s ease-out;
              opacity: 1;
              margin-top: 30px;
              transform: scale(1);
            `
        }
      }};
    }
  `,
}

export default Grid1FullscreenIntro

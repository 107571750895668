import React, { PropsWithChildren } from 'react'
import Slider, { Settings } from 'react-slick'
import styled from '@emotion/styled'

interface ImageSliderProps {
  customPagingCallback?: (index: number) => JSX.Element
  callback?: (index: number) => void
  dots?: boolean
  className?: string
  arrows?: boolean
  speed?: number
  infinite?: boolean
  fade?: boolean
}

function ImageSlider({
  dots = false,
  arrows = false,
  speed = 500,
  infinite = false,
  fade,
  customPagingCallback,
  callback,
  className,
  children,
}: PropsWithChildren<ImageSliderProps>) {
  const customPaging = (index: number): JSX.Element => {
    return customPagingCallback?.(index)
  }

  const beforeChange = (current: number, next: number): void => callback?.(next)

  const settings: Settings = {
    dots,
    infinite,
    arrows,
    speed,
    customPaging,
    beforeChange,
    fade,
    appendDots: (dots: React.ReactElement) => {
      return <ul role='tablist'>{dots}</ul>
    },
  }

  return (
    <Styled.Slider className={className} {...settings}>
      {children}
    </Styled.Slider>
  )
}

const Styled = {
  Slider: styled(Slider)``,
}

export default ImageSlider

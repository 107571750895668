function pad2(number: number) {
  return (number < 10 ? '0' : '') + String(number)
}

export function formatDate(target: Date | string) {
  const date = typeof target === 'string' ? new Date(target) : target

  return `${pad2(date.getFullYear() % 100)}.${pad2(date.getMonth() + 1)}.${pad2(
    date.getDate()
  )}`
}

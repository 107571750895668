import React, {
  PropsWithChildren,
  useEffect,
  useRef,
  VideoHTMLAttributes,
} from 'react'
import { ComponentStyledOptionalProps } from '@/types/Component'

interface VideoProps
  extends ComponentStyledOptionalProps,
    VideoHTMLAttributes<{}> {
  sourceType?: string
  play?: boolean
  imageSrc?: string
  imageAlt?: string
}

function Video({
  children,
  className,
  src,
  imageSrc,
  imageAlt = '',
  sourceType = 'video/mp4',
  play,
  ...videoProps
}: PropsWithChildren<VideoProps>) {
  videoProps = {
    muted: true,
    playsInline: true,
    loop: true,
    autoPlay: play ?? true,
    ...videoProps,
  }

  const videoRef = useRef<HTMLVideoElement | null>(null)
  useEffect(() => {
    if (!play) {
      return
    }

    // eslint-disable-next-line
    videoRef?.current?.play?.()?.then()
  }, [play, videoRef])

  return (
    <video {...videoProps} ref={videoRef} className={className}>
      <source {...{ src, type: sourceType }} className={className} />
      {imageSrc && (
        <img className={className} src={imageSrc} alt={imageAlt || ''} />
      )}
      {children}
    </video>
  )
}

export default Video

import React, { PropsWithChildren, ReactNode, useState } from 'react'
import {
  ContentBackgroundItem,
  ContentImageItem,
  ContentRow,
  ContentTabItem,
  ContentTextItem,
} from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import Grid from '@/components/Grid'
import { ImageFitType } from '@/enums/Content'
import { FONT_16, FONT_18_EXTRA_BOLD, FONT_22_EXTRA_BOLD } from '@/styles/fonts'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_COLOR } from '@/styles/variables'
import {
  STYLE_SYSTEM_GRID_INSIDE,
  STYLE_SYSTEM_GRID_OUTSIDE,
} from '@/styles/presets'
import Slider from 'react-slick'
import { trackEvent } from '@/helpers/logger'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid1FullBackgroundSliderProps {
  item: ContentRow
}

interface Grid1FullBackgroundSliderStyledProps {
  isCurrent?: boolean
}

const Partial = {
  Background({
    items,
    children,
  }: {
    items: ContentBackgroundItem[]
    children: ReactNode
  }) {
    const [item] = items
    return (
      <DisplayConfigWrapper show={item.show}>
        <Styled.BackgroundImage {...item}>{children}</Styled.BackgroundImage>
      </DisplayConfigWrapper>
    )
  },
  Title({ items }: { items: ContentTextItem[] }) {
    const [item] = items
    return (
      <DisplayConfigWrapper show={item.show}>
        <Styled.Title as={item.as}>{item.value}</Styled.Title>
      </DisplayConfigWrapper>
    )
  },
  Text({ items }: { items: ContentTextItem[] }) {
    const [item] = items
    return (
      <DisplayConfigWrapper show={item.show}>
        <Styled.Text>{item.value}</Styled.Text>
      </DisplayConfigWrapper>
    )
  },
  ImageSlider({
    items,
    paging,
    callback,
    currentIndex,
  }: {
    items: ContentImageItem[]
    paging: ContentTabItem[]
    callback: (index: any) => void
    currentIndex: number
  }) {
    const customPaging = (index: number) => {
      return (
        <button type='button' role='tab' aria-selected={currentIndex === index}>
          {paging?.[index]?.label}
        </button>
      )
    }

    const slideItems = items.map((item, index) => (
      <DisplayConfigWrapper
        show={item.show}
        key={`Grid1FullBackgroundSlider-slideItems-${index}`}
      >
        <Styled.Image src={item.src} alt={item.alt || ''} />
      </DisplayConfigWrapper>
    ))

    return (
      <Styled.ImageSlider
        customPaging={customPaging}
        afterChange={callback}
        dots
        infinite
        appendDots={(dots: React.ReactElement) => {
          return <ul role='tablist'>{dots}</ul>
        }}
      >
        {slideItems}
      </Styled.ImageSlider>
    )
  },
  SlideText({ items, id }: { items: ContentTextItem[]; id: number }) {
    return (
      <>
        {items.map((item, index) => {
          return (
            <Styled.SlideText
              key={`slideText${index}`}
              isCurrent={item?.tabId === id}
              aria-hidden={item?.tabId !== id}
            >
              {item?.value}
            </Styled.SlideText>
          )
        })}
      </>
    )
  },
}

function Grid1FullBackgroundSlider({
  item,
}: PropsWithChildren<Grid1FullBackgroundSliderProps>) {
  const [contentItems] = item?.columns ?? []
  const [index, setIndex] = useState<number>(0)

  const { tabImage, backgroundImage, title, text, tab, tabText } =
    elementItemHelper(contentItems.items)

  const handleSliderIndex = (index: number) => {
    const currentItem = tab.find((item) => item.order === index)
    const { logPageName, logEventName } = currentItem
    if (logEventName && logPageName)
      trackEvent({ pageName: logPageName, actionName: logEventName })
    setIndex(index)
  }

  return (
    <Grid gridType={item.type}>
      <Partial.Background items={backgroundImage}>
        <Styled.Wrap>
          <Partial.Title items={title} />
          <Partial.Text items={text} />
        </Styled.Wrap>
        <Partial.ImageSlider
          items={tabImage}
          paging={tab}
          currentIndex={index}
          callback={handleSliderIndex}
        />
        <Partial.SlideText items={tabText} id={index} />
      </Partial.Background>
    </Grid>
  )
}

const Styled = {
  BackgroundImage: styled.div<Partial<ContentBackgroundItem>>`
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 1200px;
    background-position: center;
    ${({ src, fit }) =>
      css`
        background-image: url(${src});
        background-size: ${fit === ImageFitType.CONTAIN ? 'contain' : 'cover'};
      `}
  `,
  Wrap: styled.div`
    ${STYLE_SYSTEM_GRID_OUTSIDE};
  `,
  Title: styled.div`
    padding-top: 60px;
    ${FONT_22_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE01};
    ${STYLE_SYSTEM_GRID_INSIDE};
  `,
  Text: styled.p`
    padding-top: 12px;
    white-space: pre-wrap;
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    ${STYLE_SYSTEM_GRID_INSIDE};
  `,
  Image: styled.img`
    width: 100%;
    padding-top: 200px;
  `,
  ImageSlider: styled(Slider)`
    .slick-dots {
      /* display: table; */
      display: flex !important;
      width: 100%;
      box-sizing: border-box;
      top: 24px;
      bottom: initial;
      ${STYLE_SYSTEM_GRID_OUTSIDE};
    }

    .slick-dots li {
      /* display: table-cell; */
      /* width: 1%; */
      /* height: initial; */
      /* margin: 0; */
      position: relative;
      flex: 1;
      height: auto;
      box-sizing: border-box;
      padding: 0 6px;
      cursor: pointer;
      margin: 0 6px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }

    .slick-dots li button {
      padding-top: 13px;
      ${FONT_18_EXTRA_BOLD};
      border-top: 1px solid ${STYLE_COLOR.WHITE04_30};
      color: ${STYLE_COLOR.WHITE02_60};
      width: 100%;
      height: auto;
      display: inline-block;
      background: transparent none;
      box-sizing: content-box;

      &:focus,
      &:hover {
        color: ${STYLE_COLOR.YELLOW};
      }

      &::before {
        content: none;
      }
    }

    .slick-dots li.slick-active button {
      border-top: 1px solid ${STYLE_COLOR.YELLOW};
      color: ${STYLE_COLOR.YELLOW};
    }
  `,
  SlideText: styled.p<Grid1FullBackgroundSliderStyledProps>`
    position: absolute;
    ${FONT_16};
    top: 300px;
    left: 6%;
    white-space: pre-wrap;
    color: ${STYLE_COLOR.WHITE01};
    opacity: 0;
    ${({ isCurrent }) =>
      isCurrent &&
      css`
        opacity: 1;
        transform: translateY(0);
        transition: all 0.6s ease-in;
        transition-delay: 0.2s;
        top: 286px;
      `}
  `,
}

export default Grid1FullBackgroundSlider

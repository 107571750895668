import styled from '@emotion/styled'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import NewsroomListItem from './NewsroomListItem'
import { FONT_18_EXTRA_BOLD, FONT_22_EXTRA_BOLD } from '@/styles/fonts'
import { NoticeType } from '@/types/Notice'

export function getNoticeType(tabName?: string): NoticeType[] {
  switch (tabName) {
    case 'news':
      return ['NEWS']
    case 'more':
      return ['MORE']
    case 'all':
      return ['NEWS', 'MORE']
  }
}

export function getTabName(tabName?: string) {
  switch (tabName?.toLowerCase()) {
    case 'news':
      return '뉴스'
    case 'more':
      return '더 많은 이야기'
    default:
      return '전체'
  }
}

const Styled = {
  Wrap: styled.div`
    margin: 0 auto;
    padding: 80px 0;
    width: 100%;
    max-width: 1120px;

    & + & {
      border-top: 1px solid ${STYLE_COLOR.BLACK05};
    }

    li + li {
      margin-top: 50px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 60px 0;

      li + li {
        margin-top: 40px;
      }
    }
  `,
  Title: styled.h3`
    margin-bottom: 50px;
    ${FONT_22_EXTRA_BOLD};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 24px;
      ${FONT_18_EXTRA_BOLD};
    }
  `,
}
const NewsroomList = Object.assign(Styled.Wrap, {
  Title: Styled.Title,
  Item: NewsroomListItem,
})

export default NewsroomList

import { AxiosError } from 'axios'
import { Contents } from '@/types/Content'
import { callJsonContentApi } from '@/apis/jsonData'
import useSWR from 'swr'
import { ApiPath } from '@/constants/Path'

interface UseContentParam {
  contentId: number | string
}

function useContent({ contentId }: UseContentParam) {
  const { data, error, isLoading } = useSWR<Contents, AxiosError>(
    ApiPath.CONTENT(contentId),
    async () => (await callJsonContentApi({ contentId })).data
  )

  return { data, error, isLoading }
}

export default useContent

import React, { PropsWithChildren, IframeHTMLAttributes } from 'react'
import { ComponentStyledOptionalProps } from '@/types/Component'

interface IframeProps
  extends ComponentStyledOptionalProps,
    IframeHTMLAttributes<{}> {
  title?: string
}

function Iframe({
  className,
  width,
  height,
  src,
  allowFullScreen = false,
  allow = 'encrypted-media',
  title = '',
}: PropsWithChildren<IframeProps>) {
  return (
    <iframe
      className={className}
      width={width}
      height={height}
      src={src}
      allow={allow}
      allowFullScreen={allowFullScreen}
      title={title}
    ></iframe>
  )
}

export default Iframe

import AutoLinkText from '@/components/AutoLinkText'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import TransitionHandler from '@/components/TransitionHandler'
import { EffectType } from '@/enums/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import {
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react'

interface ImageWithTextProps {
  item: ContentRow
  inverted?: boolean
  contentId?: string
}

const Partial = {
  Image({
    image,
    contentId,
  }: {
    image: ContentImageItem[]
    contentId: string
  }) {
    if (!image) return null
    return (
      <>
        {image?.map((item, index) => (
          <Styled.ImageWrap
            contentId={contentId}
            key={`Grid2ImageWithText-image-${index}`}
          >
            <TransitionHandler
              effect={item?.effectType || EffectType.WIPE_RIGHT}
              order={item?.effectOrder}
            >
              <DisplayConfigWrapper show={item?.show}>
                <Styled.Image
                  {...item}
                  alt={item.alt || ''}
                  maxHeight={item.maxHeight}
                  maxWidth={item.maxWidth}
                />
              </DisplayConfigWrapper>
            </TransitionHandler>
          </Styled.ImageWrap>
        ))}
      </>
    )
  },
  SubTitle({ subTitle }: { subTitle: ContentTextItem[] }) {
    if (!subTitle) return null
    return (
      <>
        {subTitle?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ImageWithText-subTitle-${index}`}
          >
            <Styled.SubTitle>{value}</Styled.SubTitle>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ImageWithText-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ImageWithText-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid2ImageWithText-button-${index}`}
          >
            <Styled.Button {...item}>{item?.label}</Styled.Button>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid2ImageWithText({
  item,
  inverted = false,
  contentId,
}: PropsWithChildren<ImageWithTextProps>) {
  const { columns, type } = item

  const [{ items: leftColumnItems }, { items: RightColumnItems }] = columns

  const { image } = elementItemHelper(leftColumnItems)
  const { title, text, subTitle, button } = elementItemHelper(RightColumnItems)

  return (
    <Grid gridType={type} inverted={inverted}>
      <Partial.Image image={image} contentId={contentId} />
      <TransitionHandler>
        <Styled.TextWrap>
          <Partial.SubTitle subTitle={subTitle} />
          <Partial.Title title={title} />
          <Partial.Text text={text} />
          <Partial.Button button={button} />
        </Styled.TextWrap>
      </TransitionHandler>
    </Grid>
  )
}

const Styled = {
  ImageWrap: styled.div<{ contentId: string }>`
    ${({ contentId }) => {
      if (contentId === 'main') {
        return css`
          @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
            /* 메인페이지 - 비디오 좌우여백 제거 */
            margin: 0 calc(-5vw + -6px);
          }
        `
      }
    }};
  `,
  Image: styled.img<{
    maxWidth: number | string
    maxHeight: number | string
  }>`
    width: 100%;

    ${({ maxWidth, maxHeight }) =>
      css`
        max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
        max-height: ${typeof maxHeight === 'number'
          ? `${maxHeight}px`
          : maxHeight};
      `};
  `,
  TextWrap: styled.div`
    width: 66.7%;
    margin: 0 auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: auto;
      margin-top: 24px;
    }
  `,
  SubTitle: styled.div`
    ${STYLE_KEEP_WORD};
    ${FONT_22_EXTRA_BOLD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Title: styled.div`
    ${STYLE_KEEP_WORD};
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    ${STYLE_KEEP_WORD};
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    display: block;
    padding-top: 4px;

    &:first-of-type {
      padding-top: 30px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
}

export default Grid2ImageWithText

import React, { PropsWithChildren, ReactNode } from 'react'
import {
  ContentBackgroundItem,
  ContentButtonItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import Grid from '@/components/Grid'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { ImageFitType } from '@/enums/Content'
import AutoLinkText from '@/components/AutoLinkText'
import {
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { STYLE_ARROW_BUTTON_WHITE } from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid1FullBackgroundImageProps {
  item: ContentRow
}

const Partial = {
  BackgroundImage({
    item: backgroundImages,
    children = null,
  }: {
    item: ContentBackgroundItem[]
    children: ReactNode
  }) {
    if (!backgroundImages?.length) {
      return null
    }
    return (
      <>
        {backgroundImages.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid1FullBackgroundImage-backgroundImage-${index}`}
          >
            <Styled.BackgroundImage {...item}>
              <>{children}</>
            </Styled.BackgroundImage>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Title({ item: titles }: { item: ContentTextItem[] }) {
    if (!titles?.length) {
      return null
    }
    return (
      <>
        {titles.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid1FullBackgroundImage-title-${index}`}
          >
            <Styled.Title {...item}>{item?.value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  SubTitle({ item: subTitles }: { item: ContentTextItem[] }) {
    if (!subTitles?.length) {
      return null
    }
    return (
      <>
        {subTitles.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid1FullBackgroundImage-subTitle-${index}`}
          >
            <Styled.SubTitle {...item}>{item?.value}</Styled.SubTitle>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ item: buttons }: { item: ContentButtonItem[] }) {
    if (!buttons?.length) {
      return null
    }
    return (
      <>
        {buttons.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid1FullBackgroundImage-button-${index}`}
          >
            <TransitionHandler>
              <Styled.Button {...item}>{item?.label}</Styled.Button>
            </TransitionHandler>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid1FullBackgroundImage({
  item,
}: PropsWithChildren<Grid1FullBackgroundImageProps>) {
  const [column] = item?.columns
  const items = column?.items
  const { backgroundImage, title, subTitle, button } = elementItemHelper(items)
  return (
    <Partial.BackgroundImage item={backgroundImage}>
      <Grid spacing>
        <Styled.Fixer>
          <Styled.TextWrap>
            <Partial.Title item={title} />
            <Partial.SubTitle item={subTitle} />
            <Partial.Button item={button} />
          </Styled.TextWrap>
        </Styled.Fixer>
      </Grid>
    </Partial.BackgroundImage>
  )
}

const Styled = {
  BackgroundImage: styled.div<ContentBackgroundItem>`
    ${({ src, height, color, fit = ImageFitType.COVER }) =>
      src &&
      css`
        height: ${height}px;
        background-image: url(${src});
        background-size: ${fit};
        background-color: ${color};
      `};
    background-position: center;
    background-repeat: no-repeat;
  `,
  Fixer: styled.div`
    position: relative;
  `,
  TextWrap: styled.div`
    position: absolute;
    top: 120px;
    left: 0;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: 350px;
    }
  `,
  Title: styled(TransitionHandler)`
    display: block;
    ${FONT_30_EXTRA_BOLD};
    ${STYLE_KEEP_WORD};
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  SubTitle: styled(TransitionHandler)`
    display: block;
    ${FONT_30_EXTRA_BOLD};
    ${STYLE_KEEP_WORD};
    color: ${STYLE_COLOR.YELLOW};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_WHITE};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 24px;
    }
  `,
}

export default Grid1FullBackgroundImage

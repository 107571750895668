import React from 'react'
import styled from '@emotion/styled'
import AutoLinkText from '@/components/AutoLinkText'
import { STYLE_OUTLINE_BUTTON } from '@/styles/buttons'
import { FONT_18, FONT_22_EXTRA_BOLD } from '@/styles/fonts'
import { STYLE_COLOR } from '@/styles/variables'

function Custom404() {
  return (
    <Styled.Wrap>
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>페이지를 찾을 수 없습니다.</Styled.Title>
          <Styled.Description>에러코드: 404</Styled.Description>
          <Styled.Button href='/'>메인으로</Styled.Button>
        </Styled.Content>
      </Styled.Container>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    overflow: auto;
    background-color: ${STYLE_COLOR.WHITE01};
    z-index: 9999;
  `,
  Container: styled.div`
    height: 100%;
    white-space: nowrap;
    text-align: center;
    &:before {
      content: '';
      overflow: hidden;
      display: inline-block;
      width: 0px;
      height: 100%;
      vertical-align: middle;
    }
  `,
  Content: styled.div`
    white-space: normal;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  `,
  Title: styled.p`
    margin-top: 20px;
    ${FONT_22_EXTRA_BOLD};
  `,
  Description: styled.p`
    margin-top: 8px;
    ${FONT_18};
    color: ${STYLE_COLOR.BLACK02};
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON};
    margin-top: 40px;
  `,
}

export default Custom404

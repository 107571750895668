import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import Icon, { IconProps, IconTypeFile } from '@/components/Icon'
import { ContentItemType, ImageFitType } from '@/enums/Content'
import elementItemHelper from '@/helpers/elementItemHelper'

import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import {
  FONT_30_EXTRA_BOLD,
  FONT_40_EXTRA_BOLD,
  STYLE_FONT_SIZE,
  STYLE_FONT_WEIGHT,
  STYLE_KEEP_WORD,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import {
  BackgroundPathType,
  STYLE_CLEAR_FIX,
  STYLE_SAFE_BACKGROUND_IMAGE,
  STYLE_SYSTEM_GRID_INSIDE,
} from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentBackgroundItem,
  ContentImageItem,
  ContentTextItem,
  IconWithContentsRow,
} from '@/types/Content'
import React, { PropsWithChildren } from 'react'
import AutoLinkText from '../AutoLinkText'
import ComponentRender from '../pages/ComponentRender'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid2IconWithContentsProps {
  key?: string
  item: IconWithContentsRow
}

interface Grid2IconWithContentsStyleProps {
  fold: boolean
}

const ButtonGroup = styled.div`
  text-align: left;
  &:first-of-type,
  > a:first-of-type {
    margin-top: 0;
  }
  @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
    margin-top: 52px;
  }
`

const Partial = {
  TitleArea({
    icons,
    titles,
    subTitles,
  }: {
    icons: ContentBackgroundItem[]
    titles: ContentTextItem[]
    subTitles: ContentTextItem[]
  }) {
    const [icon] = icons
    return (
      <Styled.DefaultContainer>
        <Styled.Icon {...icon} />
        {subTitles?.map((subTitle, index) => (
          <DisplayConfigWrapper
            show={subTitle.show}
            key={`Grid2IconWithContentsDefaultPart-subTitle-${index}`}
          >
            <Styled.DefaultSubTitle as={subTitle?.as}>
              {subTitle.value}
            </Styled.DefaultSubTitle>
          </DisplayConfigWrapper>
        ))}
        {titles?.map((title, index) => (
          <DisplayConfigWrapper
            show={title.show}
            key={`Grid2IconWithContentsDefaultPart-title-${index}`}
          >
            <Styled.DefaultTitle as={title?.as}>
              {title.value}
            </Styled.DefaultTitle>
          </DisplayConfigWrapper>
        ))}
      </Styled.DefaultContainer>
    )
  },
  ContentsArea({ column, isExpandWidth }) {
    if (!column?.items) {
      return null
    }

    const links = column.items.filter(
      (item) =>
        item.type === ContentItemType.BUTTON && item.buttonStyle !== 'button'
    )

    const buttons = column.items.filter(
      (item) =>
        item.type === ContentItemType.BUTTON && item.buttonStyle === 'button'
    )

    return (
      <>
        <Styled.FoldableContainer isExpandWidth={isExpandWidth}>
          {column.items.map((item, index) => {
            const key = `Grid2IconWithContents-foldablePart-${index}${
              item?.value ?? item?.src
            }`
            switch (item.type) {
              case ContentItemType.IMAGE:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.Image {...item} alt={item.alt || ''} />
                  </DisplayConfigWrapper>
                )
              case ContentItemType.TITLE:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.Title>{item.value}</Styled.Title>
                  </DisplayConfigWrapper>
                )
              case ContentItemType.SUB_TITLE:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.SubTitle>{item.value}</Styled.SubTitle>
                  </DisplayConfigWrapper>
                )
              case ContentItemType.TEXT:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.Text>{item.value}</Styled.Text>
                  </DisplayConfigWrapper>
                )
              case ContentItemType.COMPONENT:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <ComponentRender name={item?.name} />
                  </DisplayConfigWrapper>
                )
            }
          })}

          {links.length > 0 && (
            <Styled.LinkGroup>
              {links.map((link, index) => (
                <DisplayConfigWrapper
                  show={link?.show}
                  key={`Grid2IconWithContents-foldablePart-Link-${index}`}
                >
                  <Styled.Link {...link}>{link?.label}</Styled.Link>
                </DisplayConfigWrapper>
              ))}
            </Styled.LinkGroup>
          )}

          {buttons.length > 0 && (
            <ButtonGroup>
              {buttons.map((button, index) => (
                <Styled.Button
                  key={`Grid2IconWithContents-foldablePart-Button-${index}`}
                  href={button.href}
                  target={button.target}
                >
                  {button.label}
                  {button.subLabel && (
                    <Styled.SubLabel>{button.subLabel}</Styled.SubLabel>
                  )}
                </Styled.Button>
              ))}
            </ButtonGroup>
          )}
        </Styled.FoldableContainer>
      </>
    )
  },
}

function Grid2IconWithContents({
  item,
}: PropsWithChildren<Grid2IconWithContentsProps>) {
  const [defaultColumn, foldableColumn] = item.columns

  const { backgroundImage, title, subTitle } = elementItemHelper(
    defaultColumn.items
  )

  if (!item?.columns) {
    return null
  }

  return (
    <Styled.Wrap>
      <Styled.Fixer>
        <>
          <Partial.TitleArea
            icons={backgroundImage}
            titles={title}
            subTitles={subTitle}
          />
          <Partial.ContentsArea
            isExpandWidth={item?.isExpandContentsWidth}
            column={foldableColumn}
          />
        </>
      </Styled.Fixer>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    min-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH};
    margin: 0 auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-width: 0;
    }
  `,
  Fixer: styled(Grid)`
    ${STYLE_CLEAR_FIX};
    position: relative;
  `,
  DefaultContainer: styled.div`
    position: relative;
    padding-bottom: 40px;
    text-align: center;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        border-top: 1px solid ${STYLE_COLOR.BLACK02};
      }
      ${STYLE_SYSTEM_GRID_INSIDE};
      position: static;
      padding-top: 50px;
      padding-bottom: 60px;
    }
  `,
  FoldableContainer: styled.div<{ isExpandWidth: boolean }>`
    position: relative;
    max-width: 1140px;
    margin: auto;
    overflow: hidden;
    transition: opacity 0.25s ease-in-out;
    padding-left: 33.3333%;
    padding-right: 16.6667%;
    padding-right: ${({ isExpandWidth }) => (isExpandWidth ? 0 : '16.6667%')};
    padding-bottom: 200px;
    padding: 0;
    text-align: center;
  `,
  Icon: styled.i<Partial<ContentBackgroundItem>>`
    position: static;
    top: 50%;
    left: 144px;
    display: block;
    width: 120px;
    height: 121px;
    margin: 0 auto;
    background-repeat: no-repeat;
    ${({ src, fit }) => css`
      background-image: url(${src});
      background-size: ${fit === ImageFitType.COVER ? 'cover' : 'contain'};
      background-position: center;
    `};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 90px;
      height: 90px;
    }
  `,
  DefaultTitle: styled.div`
    ${FONT_40_EXTRA_BOLD};
    line-height: 60px;
    ${STYLE_KEEP_WORD};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 24px;
      font-size: ${STYLE_FONT_SIZE._26};
      line-height: ${STYLE_LINE_HEIGHT._36};
    }
  `,
  DefaultSubTitle: styled.div`
    ${FONT_30_EXTRA_BOLD};
    ${STYLE_KEEP_WORD};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 24px;
      font-size: ${STYLE_FONT_SIZE._22};
      line-height: ${STYLE_LINE_HEIGHT._34};
    }
  `,
  DefaultTrigger: styled.button<Grid2IconWithContentsStyleProps>`
    position: absolute;
    top: 50%;
    right: 114px;
    display: block;
    width: 48px;
    height: 48px;
    margin: -24px 0 0;
    padding: 0;
    background: none transparent;
    border: 0 none;
    cursor: pointer;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin: 24px auto 0;
      ${({ fold }) => {
        if (fold) {
          return css`
            position: static;
          `
        }
        return css`
          position: absolute;
          opacity: 0;
        `
      }};
    }
  `,
  FoldableTrigger: styled.button`
    display: none;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0;
    background: none transparent;
    border: 0 none;
    cursor: pointer;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      margin-top: 30px;
    }
  `,
  TriggerIcon: styled(Icon)<IconProps & Grid2IconWithContentsStyleProps>`
    transition: transform 0.25s ease-out;
    transform: ${({ fold }) => fold && 'rotate(180deg)'};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      transform: scale(0.5);
      transform-origin: left;

      ${({ fold }) =>
        fold &&
        css`
          transform: scale(0.5) rotate(180deg);
          transform-origin: initial;
        `};
    }
  `,
  Image: styled.img<Partial<ContentImageItem>>`
    display: block;
    max-width: 100%;

    & ~ & {
      margin-top: 80px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      & ~ & {
        margin-top: 60px;
      }
    }
  `,
  Title: styled.div`
    ${STYLE_KEEP_WORD};
    margin-top: 50px;
    font-size: ${STYLE_FONT_SIZE._30};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    line-height: ${STYLE_LINE_HEIGHT._46};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      margin-top: 24px;
      font-size: ${STYLE_FONT_SIZE._22};
      line-height: ${STYLE_LINE_HEIGHT._34};
      text-align: left;
    }
  `,
  SubTitle: styled.div`
    ${STYLE_KEEP_WORD};
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: ${STYLE_FONT_SIZE._22};
    line-height: ${STYLE_LINE_HEIGHT._34};
    color: ${STYLE_COLOR.BLACK02};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      font-size: ${STYLE_FONT_SIZE._18};
      line-height: ${STYLE_LINE_HEIGHT._28};
      text-align: left;
    }
  `,
  Text: styled.div`
    ${STYLE_KEEP_WORD};
    margin-top: 24px;
    font-size: ${STYLE_FONT_SIZE._16};
    line-height: ${STYLE_LINE_HEIGHT._30};
    color: ${STYLE_COLOR.BLACK02};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      margin-top: 12px;
      text-align: left;
    }
  `,
  SubLabel: styled.span`
    margin-left: auto;
  `,
  LinkGroup: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 44px;
    margin-bottom: 24px;
    text-align: left;

    & + ${ButtonGroup} {
      margin-top: 40px;
    }
  `,
  Button: styled.a`
    position: relative;
    box-sizing: border-box;
    display: flex;
    max-width: 680px;
    align-items: center;
    margin-top: 24px;
    padding: 8px 53px 8px 20px;
    font-size: ${STYLE_FONT_SIZE._16};
    border: 1px solid ${STYLE_COLOR.BLACK03};
    text-decoration: none;
    line-height: ${STYLE_LINE_HEIGHT._30};
    text-align: left;
    color: ${STYLE_COLOR.BLACK01};

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: calc(50% - 8px);

      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_16_DOWNLOAD,
        16,
        16,
        BackgroundPathType.ICONS
      )};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 10px;
      text-align: center;
      &:after {
        display: none;
      }
    }
  `,
  Link: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    margin-top: 12px;
    &:first-of-type {
      margin-top: 0;
    }
  `,
}

export default Grid2IconWithContents

import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import List from '@/components/List'
import Table from '@/components/Table'
import TransitionHandler from '@/components/TransitionHandler'
import { GridVerticalAlignType } from '@/enums/Grid'
import elementItemHelper from '@/helpers/elementItemHelper'
import { FONT_16, FONT_22_EXTRA_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import React from 'react'
import styled from '@emotion/styled'
import AutoLinkText from '@/components/AutoLinkText'
import { STYLE_OUTLINE_BUTTON_LINK } from '@/styles/buttons'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentListItem,
  ContentTableItem,
  ContentTextItem,
} from '@/types/Content'

const Partial = {
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`GridTitleWithContents-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <Styled.ButtonGroupWrap>
        {button?.map((item, index) => {
          return (
            <TransitionHandler key={`GridTitleWithContents-button-${index}`}>
              <DisplayConfigWrapper show={item.show}>
                <Styled.OutlineButton {...item}>
                  {item.label}
                </Styled.OutlineButton>
              </DisplayConfigWrapper>
            </TransitionHandler>
          )
        })}
      </Styled.ButtonGroupWrap>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`GridTitleWithContents-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Table({ table }: { table: ContentTableItem[] }) {
    if (!table) return null
    return (
      <>
        {table?.map(({ article }, index) => (
          <Table key={`Table-${index}`} article={article} />
        ))}
      </>
    )
  },
  List({ list }: { list: ContentListItem[] }) {
    if (!list) return null
    return (
      <>
        {list?.map((item, index) => (
          <Styled.List
            key={`GridTitleWithContents-list-${index}`}
            list={item.list}
          />
        ))}
      </>
    )
  },
  Image({ image }: { image?: ContentImageItem[] }) {
    if (!image) return null

    return (
      <Styled.CommunityList>
        {image?.map(({ src, alt }, index) => (
          <>
            <Styled.CommunityItems key={`GridTitleWithContents-image-${index}`}>
              <img src={src} alt={alt} />
            </Styled.CommunityItems>
          </>
        ))}
      </Styled.CommunityList>
    )
  },
}

function Grid2TitleWithContents({ item, inverted = false }) {
  const { columns, type } = item
  const [{ items: leftColumnItems }, { items: RightColumnItems }] = columns

  const { title, button } = elementItemHelper(leftColumnItems)
  const {
    text,
    list,
    table,
    button: contentButton,
    image,
  } = elementItemHelper(RightColumnItems)

  return (
    <>
      <Styled.Grid
        gridType={type}
        inverted={inverted}
        verticalAlign={GridVerticalAlignType.TOP}
      >
        <TransitionHandler>
          <Styled.Wrap>
            <Styled.TitleWrap>
              <Partial.Title title={title} />
              <Partial.Button button={button} />
            </Styled.TitleWrap>
            <div>
              <Partial.Text text={text} />
              <Partial.Table table={table} />
              <Partial.List list={list} />
              <Partial.Button button={contentButton} />
              <Partial.Image image={image} />
            </div>
          </Styled.Wrap>
        </TransitionHandler>
      </Styled.Grid>
    </>
  )
}

const Styled = {
  Grid: styled(Grid)`
    box-sizing: border-box;
    padding: 0 5%;
  `,
  Wrap: styled.div`
    display: flex;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
    }
  `,
  TitleWrap: styled.div`
    flex: 1 0 288px;
    padding-left: 16.667%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex: none;
      padding-left: 0;
    }
  `,
  Title: styled.div`
    ${FONT_22_EXTRA_BOLD};
    display: block;
    white-space: pre-line;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 20px;
      text-align: center;
    }
  `,
  Text: styled.p`
    ${FONT_16};
  `,
  OutlineButton: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON_LINK};
    margin: 0;
  `,

  List: styled(List)`
    margin-top: 40px;
  `,
  CommunityList: styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      max-width: 570px;
      margin: auto;
    }

    img {
      display: block;
      width: 100%;
    }
  `,
  CommunityItems: styled.li`
    flex: 0 1 calc(25% - 10px);
    max-width: 280px;
    min-width: 240px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 280px;
      min-width: 280px;
      margin: auto;
    }
  `,
  ButtonGroupWrap: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    & > * {
      margin-right: 12px;
      margin-bottom: 12px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex-direction: column;
      & > * {
        margin-right: 0;
      }
    }
  `,
}

export default Grid2TitleWithContents

import Grid0Horizontal from '@/components/grids/Grid0Horizontal'
import Grid0Spacing from '@/components/grids/Grid0Spacing'
import Grid1CenterAlignedText from '@/components/grids/Grid1CenterAlignedText'
import Grid1CenterAlignedTitle from '@/components/grids/Grid1CenterAlignedTitle'
import Grid1FadingSlider from '@/components/grids/Grid1FadingSlider'
import Grid1FullBackgroundImage from '@/components/grids/Grid1FullBackgroundImage'
import Grid1FullBackgroundSlider from '@/components/grids/Grid1FullBackgroundSlider'
import Grid1FullBackgroundTab from '@/components/grids/Grid1FullBackgroundTab'
import Grid1FullImage from '@/components/grids/Grid1FullImage'
import Grid1FullscreenIntro from '@/components/grids/Grid1FullscreenIntro'
import Grid1FullTab from '@/components/grids/Grid1FullTab'
import Grid1FullVideo from '@/components/grids/Grid1FullVideo'
import Grid1History from '@/components/grids/Grid1History'
import Grid1PatternBackground from '@/components/grids/Grid1PatternBackground'
import Grid1StaticIntro from '@/components/grids/Grid1StaticIntro'
import Grid1StoreButtons from '@/components/grids/Grid1StoreButtons'
import Grid2Accordion from '@/components/grids/Grid2Accordion'
import Grid2BackgroundImageWithText from '@/components/grids/Grid2BackgroundImageWithText'
import Grid2FullReport from '@/components/grids/Grid2FullReport'
import Grid2FullReportTab from '@/components/grids/Grid2FullReportTab'
import Grid2Gallery from '@/components/grids/Grid2Gallery'
import Grid2ImageWithTab from '@/components/grids/Grid2ImageWithTab'
import Grid2ImageWithText from '@/components/grids/Grid2ImageWithText'
import Grid2RightButtons from '@/components/grids/Grid2RightButtons'
import Grid2RightSingleButton from '@/components/grids/Grid2RightSingleButton'
import Grid2TitleSet from '@/components/grids/Grid2TitleSet'
import Grid2VideoWithText from '@/components/grids/Grid2VideoWithText'
import Grid3MediaCard from '@/components/grids/Grid3MediaCard'
import { ContentRowType } from '@/enums/Content'
import useContent from '@/hooks/fetch/useContent'
import {
  ContentComponentRow,
  ContentHorizontalRow,
  ContentRow,
  ContentSpacingRow,
  ContentTabItem,
  IconWithContentsRow,
  Grid3CardLinkListRow,
} from '@/types/Content'
import Custom404 from '@root/pages/404'
import { useRouter } from 'next/router'
import React, { Fragment, PropsWithChildren, useCallback } from 'react'
import Grid1Agreement from './grids/Grid1Agreement'
import Grid1TitleWithText from './grids/Grid1TitleWithText'
import Grid2VideoContentsWithText from './grids/Grid2VideoContentsWithText'
import Grid2TitleWithContents from './grids/Grid2TitleWithContents'
import Grid2TitleWithContents2 from './grids/Grid2TitleWithContents2'
import Grid3CardLinkList from './grids/Grid3CardLinkList'
import { Hidden } from '@kakaomobility/tui-react'
import Grid1FullNewsLatest from './grids/Grid1FullNewsLatest'
import Grid0Meta from './grids/Grid0MetaTag'
import Grid2IconWithContents from './grids/Grid2IconWithContents'
import ComponentRender from './pages/ComponentRender'

export interface ContentConverterProps {
  pathSegments: string[]
}

function ContentConverter({
  pathSegments,
}: PropsWithChildren<ContentConverterProps>) {
  const router = useRouter()
  const pathname = router.asPath.split('?')[0].split('#')[0]
  const {
    data: content,
    error: contentError,
    isLoading: contentLoading,
  } = useContent({
    contentId: pathSegments[0] || 'main',
  })

  const tabId = getTabIdByTabName()

  // item.tabId가 존재하는 아이템인 경우 컴포넌트의 tabId와 동일한 값인지 리턴.
  // 존재하지 않는다면 항상 true 반환
  const checkSameTabId = useCallback(
    (item) => {
      if (item.tabId === undefined) return true

      const currentTabId = tabId ?? 0
      return item.tabId === currentTabId
    },
    [tabId]
  )

  function getTabIdByTabName() {
    const grid1FullTab: ContentRow = content?.rows.find(
      (row) => row.type === ContentRowType.GRID1_FULL_TAB
    )
    if (!grid1FullTab) {
      return 0
    }
    const tabItems = grid1FullTab.columns[0].items as ContentTabItem[]
    return tabItems.find((tab) => tab?.href === pathname)?.id || 0
  }

  const mapToContentComponent = useCallback(
    (item: ContentRow, index: number) => {
      const key = `Category-${pathSegments[0]}-Item-${index}`

      if (!checkSameTabId(item)) return

      switch (item.type) {
        case ContentRowType.GRID0_EMPTY:
          return null
        case ContentRowType.GRID0_META:
          return <Grid0Meta key={key} item={item} />
        case ContentRowType.GRID0_SPACING:
          return <Grid0Spacing key={key} item={item as ContentSpacingRow} />
        case ContentRowType.GRID0_HORIZONTAL:
          return (
            <Grid0Horizontal key={key} item={item as ContentHorizontalRow} />
          )
        case ContentRowType.GRID0_COMPONENT:
          return <ComponentRender name={(item as ContentComponentRow).name} />
        case ContentRowType.GRID1_FULLSCREEN_INTRO:
          return <Grid1FullscreenIntro key={key} item={item} />
        case ContentRowType.GRID1_STATIC_INTRO:
          return <Grid1StaticIntro key={key} item={item} />
        case ContentRowType.GRID1_FULL_IMAGE:
          return <Grid1FullImage key={key} item={item} />
        case ContentRowType.GRID1_FULL_VIDEO:
          return <Grid1FullVideo key={key} item={item} />
        case ContentRowType.GRID1_CENTER_ALIGNED_TEXT:
          return <Grid1CenterAlignedText key={key} item={item} />
        case ContentRowType.GRID1_HISTORY_LINE:
          return <Grid1History key={key} item={item} />
        case ContentRowType.GRID1_FULL_TAB:
          return <Grid1FullTab key={key} item={item} activeTabId={tabId} />
        case ContentRowType.GRID1_FULL_BACKGROUND_TAB:
          return <Grid1FullBackgroundTab key={key} item={item} />
        case ContentRowType.GRID1_STORE_BUTTONS:
          return <Grid1StoreButtons key={key} item={item} />
        case ContentRowType.GRID1_FULL_BACKGROUND_SLIDER:
          return <Grid1FullBackgroundSlider key={key} item={item} />
        case ContentRowType.GRID1_PATTERN_BACKGROUND:
          return <Grid1PatternBackground key={key} item={item} />
        case ContentRowType.GRID1_CENTER_ALIGNED_TITLE:
          return <Grid1CenterAlignedTitle key={key} item={item} />
        case ContentRowType.GRID1_FADING_SLIDER:
          return <Grid1FadingSlider key={key} item={item} />
        case ContentRowType.GRID1_FULL_BACKGROUND_IMAGE:
          return <Grid1FullBackgroundImage key={key} item={item} />
        case ContentRowType.GRID1_AGREEMENT:
          return <Grid1Agreement key={key} item={item} />
        case ContentRowType.GRID1_TITLE_WITH_TEXT:
          return <Grid1TitleWithText key={key} item={item} />
        case ContentRowType.GRID1_FULL_NEWS_LATEST:
          return <Grid1FullNewsLatest key={key} />

        case ContentRowType.GRID2_FULL_REPORT:
          return <Grid2FullReport key={key} item={item} />
        case ContentRowType.GRID2_FULL_REPORT_TAB:
          return <Grid2FullReportTab key={key} item={item} />
        case ContentRowType.GRID2_IMAGE_WITH_TEXT:
        case ContentRowType.GRID2_IMAGE_WITH_TEXT_SPACING:
        case ContentRowType.GRID2_INVERTED_IMAGE_WITH_TEXT:
        case ContentRowType.GRID2_INVERTED_IMAGE_WITH_TEXT_SPACING:
          return (
            <Grid2ImageWithText
              key={key}
              item={item}
              contentId={pathSegments[0]}
              inverted={
                item.type === ContentRowType.GRID2_INVERTED_IMAGE_WITH_TEXT ||
                item.type ===
                  ContentRowType.GRID2_INVERTED_IMAGE_WITH_TEXT_SPACING
              }
            />
          )
        case ContentRowType.GRID2_IMAGE_WITH_TAB:
          return <Grid2ImageWithTab key={key} item={item} />
        case ContentRowType.GRID2_TITLE_SET:
          return <Grid2TitleSet key={key} item={item} />
        case ContentRowType.GRID2_GALLERY:
          return <Grid2Gallery key={key} item={item} />
        case ContentRowType.GRID2_ACCORDION:
          return <Grid2Accordion key={key} item={item} />
        case ContentRowType.GRID2_BACKGROUND_IMAGE_WITH_TEXT:
        case ContentRowType.GRID2_INVERTED_BACKGROUND_IMAGE_WITH_TEXT:
          return (
            <Grid2BackgroundImageWithText
              key={key}
              item={item}
              inverted={
                item.type ===
                ContentRowType.GRID2_INVERTED_BACKGROUND_IMAGE_WITH_TEXT
              }
            />
          )
        case ContentRowType.GRID2_RIGHT_BUTTONS:
          return <Grid2RightButtons key={key} item={item} />
        case ContentRowType.GRID2_RIGHT_SINGLE_BUTTON:
          return <Grid2RightSingleButton key={key} item={item} />
        case ContentRowType.GRID2_VIDEO_WITH_TEXT:
        case ContentRowType.GRID2_INVERTED_VIDEO_WITH_TEXT:
        case ContentRowType.GRID2_VIDEO_WITH_TEXT_SPACING:
        case ContentRowType.GRID2_INVERTED_VIDEO_WITH_TEXT_SPACING:
          return (
            <Grid2VideoWithText
              key={key}
              item={item}
              contentId={pathSegments[0]}
              inverted={
                item.type === ContentRowType.GRID2_INVERTED_VIDEO_WITH_TEXT ||
                item.type ===
                  ContentRowType.GRID2_INVERTED_VIDEO_WITH_TEXT_SPACING
              }
            />
          )

        case ContentRowType.GRID2_VIDEO_CONTENTS_WITH_TEXT:
        case ContentRowType.GRID2_VIDEO_CONTENTS_WITH_TEXT_SPACING:
        case ContentRowType.GRID2_INVERTED_VIDEO_CONTENTS_WITH_TEXT:
        case ContentRowType.GRID2_INVERTED_VIDEO_CONTENTS_WITH_TEXT_SPACING:
          return (
            <Grid2VideoContentsWithText
              key={key}
              item={item}
              inverted={
                item.type ===
                  ContentRowType.GRID2_INVERTED_VIDEO_CONTENTS_WITH_TEXT ||
                item.type ===
                  ContentRowType.GRID2_INVERTED_VIDEO_CONTENTS_WITH_TEXT_SPACING
              }
            />
          )

        case ContentRowType.GRID2_TITLE_WITH_CONTENTS:
          return <Grid2TitleWithContents key={key} item={item} />

        case ContentRowType.GRID2_TITLE_WITH_CONTENTS2:
          return <Grid2TitleWithContents2 key={key} item={item} />
        case ContentRowType.GRID2_ICON_WITH_CONTENTS:
          return (
            <Grid2IconWithContents
              key={key}
              item={item as IconWithContentsRow}
            />
          )

        case ContentRowType.GRID3_MEDIA_CARD:
          return <Grid3MediaCard key={key} item={item} />
        case ContentRowType.GRID3_CARD_LINK_LIST:
          return (
            <Grid3CardLinkList key={key} item={item as Grid3CardLinkListRow} />
          )
      }
      // 기본 값 필요 시, switch > default 활용
      return null
    },
    [pathSegments, checkSameTabId, tabId]
  )

  if (!content && !contentLoading && !!contentError) {
    return <Custom404 />
  }

  if (!content && contentLoading) {
    // TODO: 로딩 화면 채우기
    return null
  }

  const mapToHiddenItem = (
    item: ContentRow | ContentSpacingRow | ContentHorizontalRow,
    index
  ) => {
    const key = `Category-${pathSegments[0]}-Hidden-${index}`
    if (!checkSameTabId(item)) return

    return item.hiddenHeading ? (
      <Hidden key={key} as={item.hiddenHeading.as}>
        {item.hiddenHeading.text}
      </Hidden>
    ) : null
  }

  return (
    <>
      {content?.rows?.map((item, index) => (
        <Fragment key={`${pathSegments[0]}-${index}-wrap`}>
          {mapToHiddenItem(item, index)}
          {mapToContentComponent(item as ContentRow, index)}
        </Fragment>
      ))}
    </>
  )
}

export default ContentConverter

import AutoLinkText from '@/components/AutoLinkText'
import RenderOnly from '@/components/RenderOnly'
import {
  STYLE_OUTLINE_BUTTON_DEFAULT,
  STYLE_OUTLINE_BUTTON_LINK,
} from '@/styles/buttons'
import { FONT_14, FONT_16_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { TColors } from '@kakaomobility/tui-core'
import styled from '@emotion/styled'

function InquiryTable() {
  return (
    <Styled.Wrap>
      <Styled.WrapContent>
        <Styled.Title>카카오모빌리티</Styled.Title>
        <Styled.Contents>
          <Styled.SubTitle>
            개인정보 보호책임자 / 위치정보관리책임자
          </Styled.SubTitle>
          <Styled.Desc style={{ marginTop: 8 }}>최진욱</Styled.Desc>

          <Styled.SubTitle style={{ marginTop: 66 }}>
            고충처리 부서
          </Styled.SubTitle>
          <Styled.Desc style={{ marginTop: 8 }}>
            카카오모빌리티 개인정보팀
            <br />
            카카오모빌리티 고객지원
          </Styled.Desc>
          <br />
          <Styled.OutlineButton
            href='https://service.kakaomobility.com/cs/'
            style={{ marginTop: 20 }}
          >
            <RenderOnly.Pc>
              카카오모빌리티 고객지원 사이트 바로가기
            </RenderOnly.Pc>
            <RenderOnly.Mobile>
              카카오모빌리티 고객지원 사이트
            </RenderOnly.Mobile>
          </Styled.OutlineButton>
          <Styled.OutlineButton href='https://service.kakaomobility.com/cs/request.html?inqry_ty_l=INQ22029329'>
            <RenderOnly.Pc>
              카카오모빌리티 문의하기 게시판 바로가기
            </RenderOnly.Pc>
            <RenderOnly.Mobile>
              카카오모빌리티 문의하기 게시판
            </RenderOnly.Mobile>
          </Styled.OutlineButton>
        </Styled.Contents>
      </Styled.WrapContent>
      <Styled.WrapContent>
        <Styled.Title>권익침해 구제</Styled.Title>
        <Styled.Contents style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Styled.ContactGroupWrap>
            <Styled.SubTitle>개인정보침해신고센터</Styled.SubTitle>
            <Styled.ContactWrap>
              <Styled.ContactName>전화</Styled.ContactName>
              <Styled.Desc>(국번없이) 118</Styled.Desc>
            </Styled.ContactWrap>
            <Styled.ContactWrap>
              <Styled.ContactName>사이트</Styled.ContactName>
              <Styled.ContactSiteLink href='http://privacy.kisa.or.kr'>
                http://privacy.kisa.or.kr
              </Styled.ContactSiteLink>
            </Styled.ContactWrap>
          </Styled.ContactGroupWrap>

          <Styled.ContactGroupWrap>
            <Styled.SubTitle>경찰청 사이버수사국</Styled.SubTitle>
            <Styled.ContactWrap>
              <Styled.ContactName>전화</Styled.ContactName>
              <Styled.Desc>(국번없이) 182</Styled.Desc>
            </Styled.ContactWrap>
            <Styled.ContactWrap>
              <Styled.ContactName>사이트</Styled.ContactName>
              <Styled.ContactSiteLink href='https://ecrm.cyber.go.kr'>
                https://ecrm.cyber.go.kr
              </Styled.ContactSiteLink>
            </Styled.ContactWrap>
          </Styled.ContactGroupWrap>

          <Styled.ContactGroupWrap>
            <Styled.SubTitle>대검찰청</Styled.SubTitle>
            <Styled.ContactWrap>
              <Styled.ContactName>전화</Styled.ContactName>
              <Styled.Desc>(국번없이) 1301</Styled.Desc>
            </Styled.ContactWrap>
            <Styled.ContactWrap>
              <Styled.ContactName>사이트</Styled.ContactName>
              <Styled.ContactSiteLink href='http://www.spo.go.kr'>
                http://www.spo.go.kr
              </Styled.ContactSiteLink>
            </Styled.ContactWrap>
          </Styled.ContactGroupWrap>

          <Styled.ContactGroupWrap>
            <Styled.SubTitle>개인정보분쟁조정위원회</Styled.SubTitle>
            <Styled.ContactWrap>
              <Styled.ContactName>전화</Styled.ContactName>
              <Styled.Desc>1833-6972</Styled.Desc>
            </Styled.ContactWrap>
            <Styled.ContactWrap>
              <Styled.ContactName>사이트</Styled.ContactName>
              <Styled.ContactSiteLink href='https://www.kopico.go.kr/'>
                https://www.kopico.go.kr/
              </Styled.ContactSiteLink>
            </Styled.ContactWrap>
          </Styled.ContactGroupWrap>
        </Styled.Contents>
      </Styled.WrapContent>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: flex;
    }
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
    }
  `,
  WrapContent: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid ${STYLE_COLOR.BLACK04};

    &:last-of-type {
      border-right: 0;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      border-right: 0;

      & + & {
        margin-top: 40px;
      }
    }
  `,
  Title: styled.dt`
    padding: 13px 0;
    ${FONT_16_BOLD};
    border-top: 2px solid #000;
    border-bottom: 1px solid ${STYLE_COLOR.BLACK04};
    text-align: center;
    color: #000;
  `,

  Contents: styled.dd`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 56px 32px;
    border-bottom: 1px solid ${STYLE_COLOR.BLACK04};
    text-align: left;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 40px 0;
    }
  `,

  OutlineButton: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON_DEFAULT};
    width: 300px;
    padding: 10px 0;
    text-align: center;
    margin-top: 10px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_OUTLINE_BUTTON_LINK};
      display: flex;
      width: 100%;
      margin-left: 0;

      &:first-of-type {
        margin-top: 20px;
      }
    }
  `,

  SubTitle: styled.p`
    ${FONT_16_BOLD};
  `,
  Desc: styled.span`
    display: inline-block;
    ${FONT_14};
  `,
  ContactGroupWrap: styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    // pc화면에서도 컨텐츠 영역이 좁아서 줄바꿈으로 스타일 깨짐 이슈 생김. 임의로 1650px 설정.
    @media (max-width: 1650px) {
      width: 100%;
      & + & {
        margin-top: 40px;
      }
    }
  `,
  ContactWrap: styled.div`
    margin-top: 16px;
    & + & {
      margin-top: 4px;
    }
  `,
  ContactName: styled.span`
    display: inline-block;
    width: 48px;
    ${FONT_14};
    color: ${TColors.NEUTRAL3};
  `,
  ContactSiteLink: styled(AutoLinkText)`
    display: inline-block;
    ${FONT_14};
    color: ${TColors.BLACK1};
    text-decoration: underline;
  `,
}

export default InquiryTable

import React, { useState } from 'react'
import {
  ContentBackgroundItem,
  ContentRow,
  ContentTabItem,
  ContentTextItem,
} from '@/types/Content'
import ImageSlider from '@/components/ImageSlider'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import {
  STYLE_SYSTEM_GRID_INSIDE,
  STYLE_SYSTEM_GRID_OUTSIDE,
} from '@/styles/presets'
import {
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ContentShowType } from '@/enums/Content'
import { trackEvent } from '@/helpers/logger'
import RenderOnly from '../RenderOnly'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid1FadingSliderProps {
  item: ContentRow
}

interface Grid1FadingSliderStyledProps {
  src?: string
  imageWidth?: number | string
  imageHeight?: number | string
  isCurrent?: boolean
}

const Partial = {
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null

    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid1FadingSlider-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid1FadingSlider-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  SlideText({ text, id }: { text: ContentTextItem[]; id: number }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show, tabId }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid1FadingSlider-slideText-${index}`}
          >
            <Styled.SlideText
              isCurrent={tabId === id}
              aria-hidden={tabId !== id}
            >
              {value}
            </Styled.SlideText>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  ImageSlider({
    backgroundImage,
    paging,
    callback,
  }: {
    backgroundImage: ContentBackgroundItem[]
    paging: ContentTabItem[]
    callback: (index: number) => void
  }) {
    const [selectedIndex, setSelectedIndex] = useState(0)
    if (!backgroundImage) return null

    const customPaging = (index: number) => {
      return (
        <button
          type='button'
          role='tab'
          aria-selected={selectedIndex === index}
        >
          {paging?.[index]?.label}
        </button>
      )
    }

    const slideItems = backgroundImage.map(
      ({ show, src, width, height }, index) => (
        <DisplayConfigWrapper
          show={show}
          key={`Grid1FullBackgroundSlider-image-${index}`}
        >
          <Styled.BackgroundImageWrap>
            <Styled.BackgroundImage
              src={src}
              imageWidth={width}
              imageHeight={height}
            />
          </Styled.BackgroundImageWrap>
        </DisplayConfigWrapper>
      )
    )

    return (
      <Styled.ImageSlider
        infinite
        fade
        dots
        speed={1000}
        callback={(index) => {
          setSelectedIndex(index)
          callback?.(index)
        }}
        customPagingCallback={customPaging}
      >
        {slideItems}
      </Styled.ImageSlider>
    )
  },
}

function Grid1FadingSlider({ item }: Grid1FadingSliderProps) {
  const [contentItems] = item?.columns ?? []
  const [index, setIndex] = useState<number>(0)

  const {
    backgroundImage = [],
    title,
    text,
    tab,
    tabText,
  } = elementItemHelper(contentItems.items)

  const pcImage = backgroundImage.filter(
    (item) => item.show === ContentShowType.PC
  )
  const mobileImage = backgroundImage.filter(
    (item) => item.show === ContentShowType.MOBILE
  )

  const handleSliderIndex = (index: number) => {
    const currentItem = tab.find((item) => item.order === index)
    const { logPageName, logEventName } = currentItem
    trackEvent({ pageName: logPageName, actionName: logEventName })
    setIndex(index)
  }

  return (
    <Styled.Wrap>
      <Styled.TextWrap>
        <Partial.Title title={title} />
        <Partial.Text text={text} />
      </Styled.TextWrap>
      <RenderOnly.Pc>
        <Partial.ImageSlider
          backgroundImage={pcImage}
          paging={tab}
          callback={handleSliderIndex}
        />
      </RenderOnly.Pc>
      <RenderOnly.Mobile>
        <Partial.ImageSlider
          backgroundImage={mobileImage}
          paging={tab}
          callback={handleSliderIndex}
        />
      </RenderOnly.Mobile>
      <Partial.SlideText text={tabText} id={index} />
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    position: relative;
  `,
  TextWrap: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    ${STYLE_SYSTEM_GRID_OUTSIDE};
  `,
  Title: styled.div`
    padding: 109px 0 24px;
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE01};
    ${STYLE_SYSTEM_GRID_INSIDE};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
      padding-top: 60px;
      padding-bottom: 0;
    }
  `,
  Text: styled.p`
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    white-space: pre-wrap;
    ${STYLE_SYSTEM_GRID_INSIDE};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  SlideText: styled.p<Grid1FadingSliderStyledProps>`
    position: absolute;
    top: 109px;
    left: 37.6%;
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    white-space: pre-wrap;
    z-index: 1;
    opacity: 0;

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${({ isCurrent }) =>
        isCurrent &&
        css`
          opacity: 1;
          transform: translateY(0);
          transition: all 0.6s ease-in;
          transition-delay: 0.2s;
          left: 41.6%;
        `};
    }
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: 344px;
      left: auto;
      ${STYLE_SYSTEM_GRID_OUTSIDE};
      margin-left: 6px;
      ${({ isCurrent }) =>
        isCurrent &&
        css`
          opacity: 1;
          transform: translateY(0);
          transition: all 0.6s ease-in;
          transition-delay: 0.2s;
          top: 330px;
        `};
    }
  `,
  BackgroundImageWrap: styled.div`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: 100%;
      max-height: 1200px;
      overflow: hidden;
      display: flex;
      align-items: baseline;
    }
  `,
  BackgroundImage: styled.div<Grid1FadingSliderStyledProps>`
    ${({ src, imageHeight }) => css`
      background-image: url(${src});
      width: 100%;
      height: ${typeof imageHeight === 'number'
        ? `${imageHeight}px`
        : imageHeight};
      background-position: center -14px;
      background-size: cover;
      background-repeat: no-repeat;
    `};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: 0;
      padding-top: 100vh;
      background-position: center bottom;
    }
  `,
  ImageSlider: styled(ImageSlider)`
    // react-slick 기본 스타일 제거
    .slick-dots li button:before {
      display: none;
    }

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      .slick-dots {
        ${STYLE_SYSTEM_GRID_OUTSIDE};
        bottom: 109px;
        z-index: 2;
        width: 300px;
        text-align: left;
      }
      .slick-dots li {
        border-bottom: 1px solid ${STYLE_COLOR.WHITE04_30};
        position: relative;
        display: inline-block;
        width: 100%;
        height: initial;
        box-sizing: border-box;
        margin: 0;
        padding: 0 6px;
        cursor: pointer;
      }
      .slick-dots li button {
        padding: 12px 0;
        ${FONT_22_EXTRA_BOLD};
        color: ${STYLE_COLOR.WHITE02_60};
        width: 100%;
        display: inline-block;
        height: auto;
        text-align: left;
      }
      .slick-dots li.slick-active button {
        color: ${STYLE_COLOR.YELLOW};
      }
    }
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      .slick-dots {
        display: flex !important;
        box-sizing: border-box;
        top: 250px;
        bottom: initial;
        ${STYLE_SYSTEM_GRID_OUTSIDE};
        z-index: 2;
      }
      .slick-dots li {
        position: relative;
        flex: 1;
        height: initial;
        box-sizing: border-box;
        margin: 0;
        padding: 0 6px;
        cursor: pointer;
      }
      .slick-dots li button {
        padding: 13px 0;
        ${FONT_18_EXTRA_BOLD};
        border-top: 1px solid ${STYLE_COLOR.WHITE04_30};
        color: ${STYLE_COLOR.WHITE02_60};
        width: 100%;
        display: inline-block;
      }
      .slick-dots li.slick-active button {
        border-top: 1px solid ${STYLE_COLOR.YELLOW};
        color: ${STYLE_COLOR.YELLOW};
      }
    }
  `,
}

export default Grid1FadingSlider

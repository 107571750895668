import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import { ContentShowType } from '@/enums/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import { FONT_16, FONT_22_EXTRA_BOLD, FONT_30_EXTRA_BOLD } from '@/styles/fonts'
import {
  STYLE_SYSTEM_GRID_INSIDE,
  STYLE_SYSTEM_GRID_OUTSIDE,
} from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ContentImageItem, ContentRow, ContentTextItem } from '@/types/Content'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

interface Grid2BackgroundImageWithTextProps {
  item: ContentRow
  inverted?: boolean
}

const Partial = {
  Image({
    image,
    inverted = false,
  }: {
    image: ContentImageItem[]
    inverted: boolean
  }) {
    if (!image) return null
    return (
      <>
        {image.map(({ src, alt, show, width }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2BackgroundImageWithText-image-${index}`}
          >
            <Styled.Image
              src={src}
              alt={alt || ''}
              imageWidth={width}
              inverted={inverted}
            />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  PcTitle({ title }: { title: ContentTextItem }) {
    return (
      <DisplayConfigWrapper show={title?.show}>
        <Styled.Title as={title?.as}>{title?.value}</Styled.Title>
      </DisplayConfigWrapper>
    )
  },
  PcText({ text }: { text: ContentTextItem }) {
    return (
      <DisplayConfigWrapper show={text?.show}>
        <Styled.Text>{text?.value}</Styled.Text>
      </DisplayConfigWrapper>
    )
  },
  MobileTitle({ title }: { title: ContentTextItem }) {
    return (
      <DisplayConfigWrapper show={title?.show}>
        <Styled.Title as={title?.as}>{title?.value}</Styled.Title>
      </DisplayConfigWrapper>
    )
  },
  MobileText({ text }: { text: ContentTextItem }) {
    return (
      <DisplayConfigWrapper show={text?.show}>
        <Styled.Text>{text?.value}</Styled.Text>
      </DisplayConfigWrapper>
    )
  },
}

function Grid2BackgroundImageWithText({
  item,
  inverted,
}: Grid2BackgroundImageWithTextProps) {
  const { columns, type } = item
  const [{ items: leftColumnItems }, { items: rightColumnItems }] = columns ?? [
    {},
    {},
  ]

  const { image } = elementItemHelper(rightColumnItems)
  const { title, text } = elementItemHelper(leftColumnItems)

  const [pcTitle] = title.filter((item) => item.show === ContentShowType.PC)
  const [pcText] = text.filter((item) => item.show === ContentShowType.PC)
  const [mobileTitle] = title.filter(
    (item) => item.show === ContentShowType.MOBILE
  )
  const [mobileText] = text.filter(
    (item) => item.show === ContentShowType.MOBILE
  )

  return (
    <Styled.Wrap>
      <Styled.BackgroundWrap>
        <Grid gridType={type} inverted={inverted}>
          <Styled.TextWrap>
            <Partial.PcTitle title={pcTitle} />
            <Partial.PcText text={pcText} />
          </Styled.TextWrap>
          <Partial.Image image={image} inverted={inverted} />
        </Grid>
      </Styled.BackgroundWrap>
      <Styled.TextWrap>
        <Partial.MobileTitle title={mobileTitle} />
        <Partial.MobileText text={mobileText} />
      </Styled.TextWrap>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div``,
  BackgroundWrap: styled.div`
    position: relative;
    width: 100%;
    height: 650px;
    margin-top: 60px;
    background-color: ${STYLE_COLOR.YELLOW};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: 294px;
    }
  `,

  Image: styled.img<{ inverted: boolean; imageWidth: number | string }>`
    position: relative;
    margin: -60px auto 0;
    width: ${({ imageWidth }) =>
      typeof imageWidth === 'number' ? `${imageWidth}px` : imageWidth};

    height: auto;
    padding-right: 16.66%;

    ${({ inverted }) => {
      if (inverted) {
        return 'float: right;'
      }

      return css`
        float: none;
        padding-right: 0;
        padding-left: 16.66%;
      `
    }}

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: absolute;
      display: block;
      right: 0;
      left: 0;
      padding: 0;
    }
  `,
  TextWrap: styled.div`
    vertical-align: middle;
    width: 66.6%;
    margin: auto;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0;
      width: auto;
      ${STYLE_SYSTEM_GRID_OUTSIDE};
    }
  `,
  Title: styled.div`
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 24px;
      ${FONT_22_EXTRA_BOLD};
      ${STYLE_SYSTEM_GRID_INSIDE};
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    white-space: pre-wrap;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_16};
      color: ${STYLE_COLOR.BLACK02};
      padding-top: 12px;
      ${STYLE_SYSTEM_GRID_INSIDE};
    }
  `,
}

export default Grid2BackgroundImageWithText

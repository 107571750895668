import React from 'react'
import Grid from '@/components/Grid'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { STYLE_SAFE_BACKGROUND_IMAGE } from '@/styles/presets'
import {
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  FONT_40_EXTRA_BOLD,
  FONT_72_EXTRA_BOLD,
  STYLE_FONT_FAMILY,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { GridVerticalAlignType } from '@/enums/Grid'
import {
  STYLE_ARROW_BUTTON_BLACK,
  STYLE_OUTLINE_BUTTON_DOWNLOAD,
} from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import { EffectType } from '@/enums/Content'
import AutoLinkText from '@/components/AutoLinkText'
import { convertTextWithHidden } from '@/helpers/hidden'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid2TitleSetProps {
  item: ContentRow
}

interface TitleSetStyledProps {
  image?: string
  imageWidth?: number
  imageHeight?: number
  titlePadding?: boolean
  hasImage?: boolean
}

interface TitleProps {
  title: ContentTextItem[]
  hasImage: boolean
}

const Partial = {
  Number({ number }: { number: ContentTextItem[] }) {
    if (!number) return null
    return (
      <>
        {number.map(({ value, show }, index) => (
          <Styled.TransitionHandler
            effect={EffectType.FADE_UP}
            key={`Grid2TitleSet-number-${index}`}
          >
            <DisplayConfigWrapper show={show}>
              <Styled.Number>{value}</Styled.Number>
            </DisplayConfigWrapper>
          </Styled.TransitionHandler>
        ))}
      </>
    )
  },
  Title({ title, hasImage }: TitleProps) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <Styled.TransitionHandler key={`Grid2TitleSet-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.TextContainer>
                <Styled.TitleWrap>
                  <Styled.Title as={as} hasImage={hasImage}>
                    {value}
                  </Styled.Title>
                </Styled.TitleWrap>
              </Styled.TextContainer>
            </DisplayConfigWrapper>
          </Styled.TransitionHandler>
        ))}
      </>
    )
  },
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) return null
    return (
      <>
        {image?.map(({ src, alt, width, height, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2TitleSet-image-${index}`}
          >
            <Styled.TitleBackgroundImage
              image={src}
              imageWidth={Number(width)}
              imageHeight={Number(height)}
            />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <TransitionHandler
            effect={EffectType.FADE_SHORT_LEFT}
            key={`Grid2TitleSet-text-${index}`}
          >
            <DisplayConfigWrapper show={show}>
              <Styled.Text>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null

    const downloads = button.filter((item) => item.buttonStyle === 'outline')
    const buttons = button.filter((item) => item.buttonStyle !== 'outline')

    return (
      <>
        {buttons?.map((item, index) => (
          <TransitionHandler
            effect={EffectType.FADE_SHORT_LEFT}
            key={`Grid2TitleSet-button-${index}`}
          >
            <DisplayConfigWrapper show={item?.show}>
              <Styled.Button {...item}>
                <Styled.ButtonLabel>
                  {convertTextWithHidden(item?.label)}
                </Styled.ButtonLabel>
              </Styled.Button>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
        {downloads?.length > 0 && (
          <>
            {downloads?.map((button, index) => (
              <TransitionHandler
                effect={EffectType.FADE_SHORT_LEFT}
                key={`Grid2TitleSet-button-${index}`}
              >
                <Styled.DownloadButton
                  key={`Grid2TitleSet-foldablePart-Button-${index}`}
                  href={button.href}
                  target={button.target}
                  {...button}
                >
                  {button.label}
                </Styled.DownloadButton>
              </TransitionHandler>
            ))}
          </>
        )}
      </>
    )
  },
}

function Grid2TitleSet({ item }: Grid2TitleSetProps) {
  const { columns, type } = item

  const [leftColumn, rightColumn] = columns
  const { items: leftColumnItems } = leftColumn ?? {}
  const { items: rightColumnItems } = rightColumn ?? {}

  const { title, image, text: number } = elementItemHelper(leftColumnItems)
  const { text, button } = elementItemHelper(rightColumnItems)

  return (
    <Styled.Root
      gridType={type}
      verticalAlign={GridVerticalAlignType.TOP}
      stretch
    >
      <Styled.LeftColumn>
        <Partial.Number number={number} />
        <Partial.Title title={title} hasImage={!!image?.length} />
        <Partial.Image image={image} />
      </Styled.LeftColumn>
      <Styled.RightColumn>
        <Partial.Text text={text} />
        <Partial.Button button={button} />
      </Styled.RightColumn>
    </Styled.Root>
  )
}

const Styled = {
  Root: styled(Grid)`
    position: relative;
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
  `,
  TransitionHandler: styled(TransitionHandler)`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: table-cell;
      vertical-align: middle;
    }
  `,
  LeftColumn: styled.div<TitleSetStyledProps>`
    padding: 0 150px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: relative;
      padding: 0;
    }
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) and (max-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH}) {
      padding: 0 96px;
    }
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: table;
    }
  `,
  TextContainer: styled.div`
    display: table;
    width: 100%;
    height: 100%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      text-align: center;
    }
  `,
  TitleWrap: styled.div`
    ${STYLE_KEEP_WORD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: table-cell;
      vertical-align: bottom;
    }
  `,
  TitleBackgroundImage: styled.div<TitleSetStyledProps>`
    position: absolute;
    top: 0;
    left: 150px;
    z-index: -1;
    max-height: 100%;
    margin: 0 5%;
    ${({ image, imageWidth, imageHeight }) =>
      STYLE_SAFE_BACKGROUND_IMAGE(image, imageWidth, imageHeight)};
    background-size: contain !important;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
      background-position: center;
    }
  `,
  Number: styled.div`
    display: table-cell;
    ${FONT_72_EXTRA_BOLD};
    padding-right: 20px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: inline-block;
      width: 100%;
      ${FONT_40_EXTRA_BOLD};
      text-align: center;
    }
  `,
  Title: styled.div<TitleSetStyledProps>`
    ${STYLE_KEEP_WORD};
    display: inline-block;
    ${FONT_30_EXTRA_BOLD};
    padding-left: ${({ titlePadding }) => (titlePadding ? '20px' : 0)};
    vertical-align: top;
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      ${FONT_22_EXTRA_BOLD};
      ${({ hasImage }) =>
        hasImage &&
        css`
          padding: 116px 0 0;
          @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
            padding: 100px 0 16px;
          }
        `};
    }
  `,
  RightColumn: styled.div`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      text-align: center;
    }
  `,
  Text: styled.p`
    ${STYLE_KEEP_WORD};
    ${FONT_22_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 12px;
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  ButtonLabel: styled.span`
    display: inline-block;
    vertical-align: middle;
  `,
  DownloadButton: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON_DOWNLOAD};
    display: flex;
    align-items: center;
    margin-top: 40px;

    &:after {
      content: '';
      margin-left: auto;
    }
  `,
}

export default Grid2TitleSet

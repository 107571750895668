import useGetNotices from '@/hooks/fetch/useGetNotices'
import NewsroomList, { getTabName } from '../newsroom/NewsroomList'
import Link from 'next/link'
import { encodeSlug } from '@/helpers/slug'
import { formatDate } from '@/helpers/formatDate'
import Grid from '../Grid'
import styled from '@emotion/styled'
import { FONT_26_EXTRA_BOLD, FONT_40_EXTRA_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import AutoLinkText from '../AutoLinkText'
import { ContentLogPageName } from '@/enums/Content'
import { trackEvent } from '@/helpers/logger'

// 메인페이지 최신 뉴스 영역
export default function Grid1FullNewsLatest() {
  const { data: newsList } = useGetNotices({
    noticeTypes: ['NEWS', 'MORE'],
    size: 3,
  })

  return (
    <Grid>
      <NewsroomList style={{ padding: 0 }}>
        <Styled.Title>뉴스룸</Styled.Title>
        <ul>
          {newsList?.flat().map((news, index) => (
            <li key={news.id}>
              <Link
                href={`/newsroom/detail/${encodeSlug(
                  `${news.title}-${news.id}`
                )}`}
                passHref
              >
                <NewsroomList.Item
                  thumbnail={news.thumbnail_url}
                  tab={getTabName(news.notice_type)}
                  title={news.title}
                  summary={news.summary}
                  date={formatDate(news.start_at)}
                  onClick={() =>
                    trackEvent({
                      pageName: ContentLogPageName.HOME,
                      actionName: `홈_콘텐츠_뉴스룸_${index + 1}`,
                      eventMeta: { id: `${news.id}` },
                    })
                  }
                />
              </Link>
            </li>
          ))}
        </ul>
        <Styled.ButtonWrap
          onClick={() => {
            trackEvent({
              pageName: ContentLogPageName.HOME,
              actionName: '홈_콘텐츠_뉴스룸_뉴스더보기',
            })
          }}
        >
          <Styled.Button href={'/newsroom'}>
            {'<hidden>뉴스룸 </hidden>더 보기'}
          </Styled.Button>
        </Styled.ButtonWrap>
      </NewsroomList>
    </Grid>
  )
}

const Styled = {
  Title: styled.h3`
    ${FONT_40_EXTRA_BOLD};
    text-align: center;
    padding-bottom: 80px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_26_EXTRA_BOLD};
      text-align: left;
      padding-bottom: 24px;
    }
  `,
  ButtonWrap: styled.div`
    display: flex;
    justify-content: center;
    padding-top: 80px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      justify-content: start;
      padding-top: 24px;
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
  `,
}
